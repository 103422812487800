import axios from "axios";
import UserManager from "../helpers/userManager";
import Role from "../helpers/role";
import API from "../helpers/api";

export default class AuthService {

  constructor() {
    UserManager.events.addUserLoaded(user => {
      if (user.access_token && window.location.href.indexOf("signin") !== -1) {
        this.setUserInfo({
          accessToken: user.access_token,
          idToken: user.id_token,
          email: user.profile.email
        });
      }
    })

    UserManager.events.addSilentRenewError((e) => {
      console.log("silent renew error", e.message);
    });

    UserManager.events.addAccessTokenExpired(() => {
      console.log("token expired");
      this.logout();
    });
  }

  signinRedirect = () => {
    UserManager.signinRedirect();
  };

  signinRedirectCallback = () => {
    return UserManager.signinRedirectCallback()
  };

  // signinRedirectCallback = () => {
  //   UserManager.signinRedirectCallback()
  //     .then(() => {
  //       // "";
  //     })
  //     .catch((e) => {
  //       // window.location.replace("/");
  //     });
  // };

  logout = () => {
    UserManager.signoutRedirect();
    // UserManager.signoutRedirect({
    //   id_token_hint: localStorage.getItem("id_token")
    // });
    // UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      UserManager.clearStaleState();
      window.location.replace("/");
    });
  };

  navigateToScreen = (userDetails) => {
    // let user = JSON.parse(localStorage.getItem("currentUser"));
    let url = `${API.AuthenticateUser}?emailid=${userDetails.email}`;
        axios.get(url ,{
          headers: {
              key: userDetails.idToken,
          }
      })
            .then((response) => {
              if (response.data){
              if (userDetails.role === Role.client) {
                window.location.replace("/Home/ClientDashboard");
              }
              else if (userDetails.role === Role.mmpl) {
                window.location.replace("/Home/MMPlDashboard");
              }
              else if (userDetails.role === Role.mmpladmin) {
                window.location.replace("/Home/MMPlDashboard");
              }
              else if (userDetails.role === Role.staff) {
                window.location.replace("/Home/Ship/Staff/Tasks");
              } else {
                window.location.replace("/pagenotfound");
              }
            }
            else{
              window.location.replace("/usernotfound");
            }
            })
            .catch((error) => {
              console.log(error)
            })
};

  isAuthenticated = () => {
    // const oidcStorage = JSON.parse(
    //   sessionStorage.getItem(
    //     `oidc.user:${process.env.REACT_APP_IDENTITY_CONFIG_AUTHORITY}:${process.env.REACT_APP_IDENTITY_CONFIG_CLIENT_ID}`
    //   )
    // );
    let currentUser = localStorage.getItem("currentUser");
    let oidcStorage = JSON.parse(currentUser);
    return (!!oidcStorage && !!oidcStorage.idToken);
    // const access_token = localStorage.getItem("access_token");
    // return !!access_token;
  };

  getUser = async () => {
    const user = await UserManager.getUser();
    if (!user) {
      return await UserManager.signinRedirectCallback();
    }
    return user;
  };

  getUserRole = async () => {
    let userRole = localStorage.getItem("userRole");
    return userRole;
  };

  getCurrentUserDetails = async () => {
    let currentUserDetails = localStorage.getItem("currentUser");
    let userDetails = JSON.parse(currentUserDetails);
    return userDetails;
  };

  parseJwt = token => {
    const base64Url = token.split(".")[1];
    if (base64Url) {
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      return JSON.parse(window.atob(base64));
    } else {
      try {
        const parsedToken = JSON.parse(window.atob(token))
        return parsedToken
      }
      catch (error) {
        console.log(error.message)
        return token
      }
    }
  };

  setUserInfo = authResult => {
    const accessData = this.parseJwt(authResult.accessToken);
    const idData = this.parseJwt(authResult.idToken);

    // attempt to normalize result data
    const data1 = typeof accessData === 'string' ? { accessToken: authResult.accessToken } : { ...accessData }
    const data2 = typeof idData === 'string' ? { idToken: authResult.idToken } : { ...idData, email: authResult.email }
    const data = {
      ...data1,
      ...data2,
      ...authResult
    }

    localStorage.setItem('currentUser', JSON.stringify(data));
    axios.defaults.headers.common['authorization'] = `Bearer ${authResult.accessToken}`;
    this.navigateToScreen(data);
  };

  signinSilent = () => {
    UserManager.signinSilent()
      .then(user => {
        console.log("signed in", user);
      })
      .catch(err => {
        console.log(err);
      });
  };

  signinSilentCallback = () => {
    UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return UserManager.createSigninRequest();
  };

}