import React, { Component } from "react";
import Shipimage from "../Asset/PSC-Inspections.jpg";
import home from "../Asset/home.png";
import "../Asset/css/homePage.css";

class VISDetail extends Component {
    authService;
    constructor(props) {
        super(props);
    }

    reDirectSafety = () => {
        this.props.history.push('/Safety');
    }
    reDirect = () => {
        this.props.history.push('/IHM');
    }

    reDirecthome = () => {
        this.props.history.push('/');
    }  
     reDirectSRS = () => {
        this.props.history.push('/SRS');
    }
    viewMoreAlcovisor = () => {
        this.props.history.push('/Alcovisor');
    }
    viewMoreNavCom = () => {
        this.props.history.push('/NavCom');
    }


    render() {
        return (
            <div className="row" style={{ background: "white" }}>
                <div className="col-sm-12 col-md-12">
                    <div className="container-fluid bordersky is-sticky">
                        <div className="container">
                            <div className="row p-2">
                                <div className="col-12 col-md-2 center-mobile">
                                    <a href="" onClick={() => this.reDirecthome()}>
                                        <img className="logo-image" src={home} id="navbar-logo"></img>
                                    </a>
                                </div>
                                <div className="col-12 col-md-10">
                                    <ul className="navbar-list float-right pt-3 mb-2 pal-0">
                                        <li><a href="" onClick={() => this.reDirecthome()}>Home</a></li>
                                        <li><a href="" onClick={() => this.reDirect()}>IHM</a></li>
                                        <li><a href="" onClick={() => this.reDirectSafety()}>Safety</a></li>
                                        <li><a href="" onClick={() => this.viewMoreAlcovisor()}>Alcovizor</a></li>
                                        <li><a href="" onClick={() => this.viewMoreNavCom()}>NavCom</a></li>
                                        <li><a href="" onClick={() => this.reDirectSRS()}>SRS</a></li>
                                        <li><a href="#">VIS</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid mt-6 mb-4">
                        <div className="second-wrapper">
                            <div className="container">
                                <div className="row p-3 pt-4">
                                    <div className="col-6" id="content">
                                        <h5>VIS</h5>
                                    </div>

                                    <div className="col-6 text-right" id="Contact">
                                        <a href="https://www.marinemechanics.biz/contact">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container" id="body-content">
                             <div className="row">
                                 <div className="col-sm-12  col-md-6">
                                       <h5 className="bar-bg">Marine Mechanics – Vessel Inspections</h5>
                                          <p className="top-spc">We at Marine Mechanics offer detailed examination of a vessel’s condition and operational capability before it is purchased by the new owner. The inspection is conducted by our qualified marine inspector and involves a thorough examination of the vessel’s hull, machinery, electrical systems, navigational equipment, safety equipment, and other critical components.</p>
                                          <p className="pt-3 pb-4">The purpose of a pre-purchase inspection is to help the potential buyer make an informed decision about whether to purchase the vessel, and to identify any potential issues that may require repair or replacement.</p>
                                          <p className="">Some of the key factors that we consider include the vessel’s age, overall condition, maintenance history, compliance with safety regulations, and any potential environmental or safety risks. The inspector, with the help of a web-based inspection portal, will provide a detailed report of their findings and recommendations to the buyer, which can be used to negotiate the purchase price or to identify any necessary repairs or upgrades.</p>
                                          <p className="">Overall, a pre-purchase inspection is a crucial step in the process of buying a ship, we help ensure that the buyer is making a sound investment and that the vessel is safe and seaworthy.</p>
                                 </div>
                                <div className="col-sm-12  col-md-6  mt-4" ><img src={Shipimage} alt="" className="mt-5 ml-5 float-right img-border img-fluid" /> </div>
                                  </div>
                                 
                             <div className="row mt-5">
                                 <div className="col-12">
                                     <h5 className="bar-bg">Our Inspectors</h5>
                                           <p className="top-spc">With a pool of 70 plus inspectors worldwide, we provide global coverage to potential buyers and owners.</p>
                                           <p className="">Our vessel inspectors have the knowledge and experience to identify potential issues and areas of concern that may not be apparent to a less experienced observer. They have a deep understanding of marine systems and equipment and can provide an objective evaluation of a vessel’s condition and operational capability.</p>
                                           <p className="">Our inspectors conduct a comprehensive inspection of the vessel, including a detailed examination of all critical components and systems.</p>
                                           <p className="">Our inspectors will provide an objective evaluation of the vessel’s condition and performance, free from bias or influence from the buyer or seller.</p>
                                         <p className="pb-4">Our digital pre-purchase inspection report provides valuable information that can be used in negotiations with the seller regarding the purchase price or any necessary repairs or upgrades.</p>  
                                </div>
                            </div>
                           
                                       
                           
                             <div className="row mt-3">
                                <div className="col-12" >
                                     <h5 className="bar-bg mb-4">Our Digital Product</h5>
                                     <p className="top-spc">Our tool provides several advantages to vessel inspectors, buyers, and sellers. Here are a few key benefits:</p>
                                     <ul className="last-ul">
                                         <li>Efficiency: Our tool streamlines the vessel inspection process, making it faster and more efficient. Inspectors use the tool to create ship specific checklists, document findings, and generate reports, saving time and reducing the risk of errors.</li>
                                         <li>Standardization: The tool helps to ensure that inspections are conducted consistently and according to established standards for different types of vessels like Bulkers, Tankers, Containers & Gas Carriers.</li>
                                         <li>Accuracy: It improves the accuracy of inspections by providing a standardized framework for documentation and reporting. Inspectors can use the tool to record findings and observations in real-time, reducing the risk of errors and omissions.</li>
                                         <li>Accessibility: It makes the inspection reports more accessible to buyers and sellers. Reports can be easily shared and accessed online, reducing the need for paper documents and physical storage.</li>
                                    </ul>
                                    <p className="top-spc">Overall, our vessel inspections digital tool improves the efficiency, accuracy, and accessibility of vessel inspections, providing benefits to inspectors, buyers, and sellers alike.</p> 
                                    <p className="mb-4">Please reach out to us at <a href="mailto: inspections@marinemechanics.biz">inspections@marinemechanics.biz</a></p>
                                </div>
                             </div>
                        </div>
                        </div> 
                     <div className="footer p-3" id="footer-cont">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 copytext" style={{ textAlign: "center" }}>
                                    Copyright &copy; 2024 Marine Mechanics Pvt Ltd All Rights Reserved.
                            </div>
                        </div>
                    </div>

                </div>
             </div>
            
        );
    }
}

export default VISDetail;
