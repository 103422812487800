import React, { Component } from "react";
import home from "../Asset/home.png";
import MarineSafety from "../Asset/Marine-Safety-Equipments-Supply.jpg";
import "../Asset/css/homePage.css";

class Safety extends Component {
    authService;
    constructor(props) {
        super(props);
    }

    reDirect = () => {
        this.props.history.push('/IHM');
    }

    reDirecthome = () => {
        this.props.history.push('/');
    }
    reDirectSRS = () => {
        this.props.history.push('/SRS');
    }
    reDirectVIS = () => {
        this.props.history.push('/VIS');
    }
    viewMoreAlcovisor = () => {
        this.props.history.push('/Alcovisor');
    }
    viewMoreNavCom = () => {
        this.props.history.push('/NavCom');
    }
    render() {
        return (
            <div className="row" style={{ background: "white" }}>
                <div className="col-sm-12 col-md-12">
                    <div className="container-fluid bordersky is-sticky">
                        <div className="container">
                            <div className="row p-2">
                                <div className="col-12 col-md-2 center-mobile">
                                    <a href="" onClick={() => this.reDirecthome()}>
                                        <img className="logo-image" src={home} id="navbar-logo"></img>
                                    </a>
                                </div>
                                <div className="col-12 col-md-10">
                                    <ul className="navbar-list float-right pt-3 mb-2 pal-0">
                                        <li><a href="" onClick={() => this.reDirecthome()}>Home</a></li>
                                        <li><a href="" onClick={() => this.reDirect()}>IHM</a></li>
                                        <li><a href="#">Safety</a></li>
                                        <li><a href="" onClick={() => this.viewMoreAlcovisor()}>Alcovizor</a></li>
                                        <li><a href="" onClick={() => this.viewMoreNavCom()}>NavCom</a></li>
                                        <li><a href="" onClick={() => this.reDirectSRS()} >SRS</a></li>
                                        <li><a  href="" onClick={() => this.reDirectVIS()}>VIS</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid mt-6">
                        <div className="second-wrapper">
                            <div className="container">
                                <div className="row p-3 pt-4">
                                    <div className="col-6" id="content">
                                        <h5>Safety</h5>
                                    </div>

                                    <div className="col-6 text-right" id="Contact">
                                        <a href="https://www.marinemechanics.biz/contact">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container" id="body-content">

                            <div className="row">
                                <div className=" col-sm-12  col-md-6 " >
                                    <b>“Safety isn’t expensive, it’s priceless “</b>

                                    <p className="top-spc">We here in Marine Mechanics understand the value and seriousness of Marine Safety Equipment. We are a complete safety solution provider – Everything you need your vessel’s to remain safe and complaint.</p>
                                    <p className="pb-4 pt-4">The MSC requirements and IMO guidelines are constantly being reviewed and upgraded depending on the situations and events that are being experienced by the Maritime fraternity at sea. LSA and FFA code is considered to be the most important aspect of anyvessel’s safety requirements be it to be its men or materials. There are several rules and regulations framed by the flag states that are inline with the MSC circulars and guidelines to safeguard mariner’s life, environment and materials onboard.</p>

                                    <p className="">MM Safety has made an effort to harmonize these varying service needs on all the LSA and FFA items, and Our services will cover all the Annual, Biennial, 3 yearly, 5 yearly, 10 yearly service requirements as per the flag state and className requirements. </p>



                                </div>
                                <div className="col-sm-12  col-md-6  mt-0" ><img src={MarineSafety} alt="" className="mt-2 ml-5 float-right img-border img-fluid" /> </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-12" >

                                    <h5 className="bar-bg mb-4">SALIENT FEATURES</h5>


                                    <ul className="last-ul">
                                        <li>Fixed charges for inspection and maintenance worldwide</li>
                                        <li>Availability of dedicated and qualified LSA & FFA service team.</li>
                                        <li>Providing high quality and reliable service to the service requirements of all LSA & FFA items</li>
                                        <li>Single point of contact for the service, supply, and certification process</li>
                                        <li>Due date monitoring with 30, 60 and 90days notifications for compliance</li>
                                        <li>Harmonized visits reduce the number of service visits and provides proactive service support.</li>
                                    </ul>
                                    <p className="top-spc"></p>
                                    <h5 className="bar-bg mb-4 ">OTHER BENEFITS</h5>


                                    <ul className="last-ul" >
                                        <li>Overall savings on cost in terms of reduced number of transactions, consolidation of supply and services at competitive rates </li>
                                        <li>Availability of software tool to download certificates and status of vessel’s compliance.</li>
                                        <li>Globalized service availability across major ports and destinations through channel partners</li>
                                        <li>No additional charges for overtime and extra hours working.</li>
                                        <li>Consolidated invoicing helps in control of fund outflow.</li>
                                        <li>Guaranteed Completion of Service </li>
                                        <li>Savings on ship’s crew Time</li>
                                        <li>Priority on customer service</li>
                                        <li>Reduced visit and port stay due to pre-planned service activities.</li>
                                        <li>Contractual service reduces the enquiries, service cost and approval related formalities.</li>
                                        <li>Vessel based service activities increase operational efficiency.</li>
                                    </ul>

                                    <p className="top-spc mb-5">Please feel free to have a detailed discussion and obtain customized proposal by writing to <a href="mailto:mmsafety@Marinemechanics.biz">mmsafety@Marinemechanics.biz</a></p>


                                </div>

                            </div>





                        </div>
                        <div className="footer p-3" id="footer-cont">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 copytext" style={{ textAlign: "center" }}>
                                    Copyright &copy; 2024 Marine Mechanics Pvt Ltd All Rights Reserved.
            </div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        );
    }
}

export default Safety;
