import React, { Component } from "react";
import alcov from "../Asset/alcov.png";
import home from "../Asset/home.png";
import "../Asset/css/homePage.css"

class Alcovisor extends Component {
    authService;
    constructor(props) {
        super(props);
    }

    reDirect = () => {
        this.props.history.push('/Safety');
    }

    reDirecthome = () => {
        this.props.history.push('/');
    }
    reDirectSRS = () => {
        this.props.history.push('/SRS');
    }
    reDirectVIS = () => {
        this.props.history.push('/VIS');
    }
    viewMoreNavCom = () => {
        this.props.history.push('/NavCom');
    }
    reDirectIHM = () => {
        this.props.history.push('/IHM');
    }
    render() {
        return (
            <div className="row" style={{ background: "white" }}>
                <div className="col-sm-12 col-md-12">
                    <div className="container-fluid bordersky is-sticky">
                        <div className="container">
                            <div className="row p-2">
                                <div className="col-12 col-md-2 center-mobile">
                                    <a href="" onClick={() => this.reDirecthome()}>
                                        <img className="logo-image" src={home} id="navbar-logo"></img>
                                    </a>
                                </div>
                                <div className="col-12 col-md-10">
                                    <ul className="navbar-list float-right pt-3 mb-2 pal-0">
                                        <li><a href="" onClick={() => this.reDirecthome()}>Home</a></li>
                                        <li><a href="" onClick={() => this.reDirectIHM()}>IHM</a></li>
                                        <li><a href="" onClick={() => this.reDirect()}>Safety</a></li>
                                        <li><a href="#">Alcovizor</a></li>
                                        <li><a href="" onClick={() => this.viewMoreNavCom()}>NavCom</a></li>
                                        <li><a href="" onClick={() => this.reDirectSRS()}>SRS</a></li>
                                        <li><a href="" onClick={() => this.reDirectVIS()}>VIS</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid mt-6">
                        <div className="second-wrapper">
                            <div className="container">
                                <div className="row p-3 pt-4">
                                    <div className="col-6" id="content">
                                        <h5>Alcovisor</h5>
                                    </div>

                                    <div className="col-6 text-right" id="Contact">
                                        <a href="https://www.marinemechanics.biz/contact">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container" id="body-content">
                            <div className="row">
                                <div className=" col-sm-12  col-md-7 " >
                                    <h5 className="bar-bg">Safety isn’t expensive, it’s priceless</h5>

                                    <p className="top-spc">Consumption of alcohol or illegal drugs can severely impair any seafarer’s fitness for duty. The consumption of alcohol on board ship is being progressively phased out through strict regulations and screening. Many shipping companies now enforce a no-alcohol policy on-board their ships, and companies and port state inspectors may carry out random alcohol tests on crew members.</p>
                                    <p className="">Note: Even where the flag state and company have policies that do not prohibit alcohol port state regulations apply and the consumption of any alcohol may be considered an offence. The amended 2010 STCW Convention requires administration should put in place adequate measures to prevent drug and alcohol abuse.</p>

                                    <p className="">There is also a mandatory requirement for the administration to establish a limit of not greater than 0.05% blood alcohol level (BAC) or 0.25 mg/l alcohol in the breath or a quantity of alcohol leading to such alcohol concentration for masters, officers and other seafarers while performing designated safety, security, and marine environmental duties.</p>
                                    <p className="">Guidelines in part B of the STCW Code on watch-keepers recommend seafarers should not consume any alcohol within four hours of going on watch.</p>
                                    <p>Note: The scope of coverage in the amended 2010 STCW is wider now, covering not only watch-keepers but also crew with environmental and security duties.</p>
                                    <p>Over the years, Alcovisor has been widely used as an alcohol breath analyzer in many industries. For the first time in maritime industry, we are introducing the most advanced tailor-made alcohol tester specially made for the ship’s crew.</p>

                                </div>
                                <div className="col-sm-12  col-md-5  mt-4" >
                                    <div className="center-div alco-image-mt">
                                        <img src={alcov} alt="" className="img-border img-fluid" />
                                    </div>
                                </div>
                            </div>
                            {/*  */}
                            <div className="row mt-4">
                                <div className="col-sm-12  col-md-12" >
                                    <h5 className="bar-bg">Phoebus model has many features designed for dependable and accurate testing for the seafarers</h5>
                                    <ul className="last-ul pb-4">
                                        <li>A fixed unit or fix on desktop stand with camera feature.</li>
                                        <li>Wall mounted to capture picture and send the test result to shore office.</li>
                                        <li>Self-calibration by the ship’s staff.</li>
                                        <li>All the results can be stored onboard on cloud for ready access to ship and shore office.</li>
                                        <li>The test results can be sent to a specified email when there is a breach of companies alcohol policy.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row mt-4 mb-5">
                                <div className="col-12" >
                                    <h5 className="bar-bg">Phoebus model has many features designed for dependable and accurate testing for the seafarers</h5>
                                    <div className="table-responsive mt-3">
                                        <table className="table table-striped table-bordered alco-table">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th>Sensor</th>
                                                    <th>Fuel Cell</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Working Temperature</td>
                                                    <td>-10°C to 50°C</td>
                                                </tr>
                                                <tr>
                                                    <td>Storage Temperature</td>
                                                    <td>-20°C to 50°C</td>
                                                </tr>
                                                <tr>
                                                    <td>Storage Humidity</td>
                                                    <td>20% to 95%</td>
                                                </tr>
                                                <tr>
                                                    <td>Size</td>
                                                    <td>20cm x 22cm x 8.9cm</td>
                                                </tr>
                                                <tr>
                                                    <td>Weight</td>
                                                    <td>1488g</td>
                                                </tr>
                                                <tr>
                                                    <td>Battery</td>
                                                    <td>AC 100-240V 50-60Hz DC12V 3A</td>
                                                </tr>
                                                <tr>
                                                    <td>Memory</td>
                                                    <td>20,000 test records 10,000 registered faces</td>
                                                </tr>
                                                <tr>
                                                    <td>Camera</td>
                                                    <td>1 Mega Pixel</td>
                                                </tr>
                                                <tr>
                                                    <td>Response Time</td>
                                                    <td>5 seconds</td>
                                                </tr>
                                                <tr>
                                                    <td>Recovery Time</td>
                                                    <td>30 seconds after a positive test</td>
                                                </tr>
                                                <tr>
                                                    <td>Sample Time</td>
                                                    <td>4 seconds of continuous blow</td>
                                                </tr>
                                                <tr>
                                                    <td>Sampling System</td>
                                                    <td>Automatically takes a deep lung sample</td>
                                                </tr>
                                                <tr>
                                                    <td>Units of Measure</td>
                                                    <td>
                                                        BrAC: mg/L, or other units
                                                        <br />
                                                        BAC: grm%, mg/mL, mg/100mL, or other units
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Detection Range</td>
                                                    <td>0.00% BAC to 0.40% BAC</td>
                                                </tr>
                                                <tr>
                                                    <td>Accuracy</td>
                                                    <td>
                                                        0 – 0.10% BrAC ±5%
                                                        <br />
                                                        0.10 – 0.15% BrAC ±8%
                                                        <br />
                                                        &gt;0.15%: ±10%
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Calibration</td>
                                                    <td>
                                                        Replaceable alcohol testing module, no need for on-site calibration
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Connection Type</td>
                                                    <td>USB</td>
                                                </tr>
                                                <tr>
                                                    <td>Network</td>
                                                    <td>WIFI, 4G(optional), Ethernet</td>
                                                </tr>
                                                <tr>
                                                    <td>NFC</td>
                                                    <td>Support</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="footer p-3" id="footer-cont">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 copytext" style={{ textAlign: "center" }}>
                                    Copyright &copy; 2024 Marine Mechanics Pvt Ltd All Rights Reserved.
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default Alcovisor;