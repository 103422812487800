import React from "react";
import home from "../Asset/home.png";
import twent24 from "../Asset/24-7.png";
import srsimage from "../Asset/srsimage.png"
import visimage from "../Asset/VIS-Icon.png"
import AuthService from "../Authentication/AuthService";
import "../Asset/css/homePage.css";

class HomePage extends React.Component {
    authService;
    constructor(props) {
        super(props);
        this.authService = new AuthService();
        this.state = {
            isMobile: false,
        };
    }
    componentDidMount() {
        this.interval = setInterval(() => {
            this.resize()
        }, 10);
    }
    resize = () => {
        if (window.innerWidth <= 767) {
            this.setState({
                isMobile: true
            })
        }
        else {
            this.setState({
                isMobile: false
            })
        }
    }

    login = () => {
        // this.props.history.push('/login');
        window.location.href = process.env.REACT_APP_IHM_Login_URL;
    }
    loginsafety = () => {
        // this.props.history.push('/login');
        window.location.href = process.env.REACT_APP_Safety_Login_URL;
    }
    loginsafetystaging = () => {
        // this.props.history.push('/login');
        window.location.href = process.env.REACT_APP_Safety_Staging_Login_URL;
    }
    loginsafetycontract = () => {
        // this.props.history.push('/login');
        window.location.href = process.env.REACT_APP_Safety_Contract_Login_URL;
    }
    loginalcovisor = () => {
        // this.props.history.push('/login');
        window.location.href = process.env.REACT_APP_Alcovisor_Login_URL;
    }
    loginNavcom = () => {
        // this.props.history.push('/login');
        window.location.href = process.env.REACT_APP_Navcom_Login_URL;
    }
    loginsrs = () => {
        window.location.href = process.env.REACT_APP_SRS_Login_URL;
    }
    loginvis = () => {
        window.location.href = process.env.REACT_APP_VIS_Login_URL;
    }
    viewMore = () => {
        this.props.history.push('/IHM');
    }

    viewMoreSafety = () => {
        this.props.history.push('/Safety');
    }
    viewMoreAlcovisor = () => {
        this.props.history.push('/Alcovisor');
    }
    viewMoreNavCom = () => {
        this.props.history.push('/NavCom');
    }
    viewMoreSRS = () => {
        this.props.history.push('/SRS');
    }
    viewMoreVIS = () => {
        this.props.history.push('/VIS');
    }
    render() {
        return (
            <div className="row" style={{ background: "white" }}>
                <div className="col-sm-12 col-md-12">
                    <div className="container-fluid">
                        <div className="col-12 mt-3 pr-4 text-right"><a href="https://www.marinemechanics.biz/contact" id="cont-btn"><i class="fa fa-map-o" aria-hidden="true"></i> Contact Us</a></div>
                    </div>
                    <div className="container">
                        <div className="row " style={{ marginTop: "10%" }}>
                            <div className="col-12  text-center">
                                <img className="logo-image" src={home} />
                            </div>
                        </div>
                        <div className="row p-3" style={{ marginTop: "2%" }}>
                            <div className="col-12 text-center">
                                <div className="row">
                                    <div className="col-md-6 col-lg-3">
                                        <div className="widget-area no-padding blank">
                                            <div className="social-widget">
                                                <div className="title-text">IHM</div>
                                                <span id="fb"><i className="fa fa-houzz"></i></span>
                                                <ul>
                                                    <li className="login-button" data-toggle="modal" data-target="#exampleModal" ><p onClick={() => this.login()}>Login</p></li>
                                                    <li><p><a href="" onClick={() => this.viewMore()}>View More</a></p></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3">
                                        <div className="widget-area no-padding blank">
                                            <div className="social-widget">
                                                <div className="title-text">Safety</div>
                                                <span id="twitter1"><i className="fa fa-life-ring"></i></span>
                                                <ul>
                                                    <li className="login-button" data-toggle="modal" data-target="#exampleModal"><p onClick={() => this.loginsafety()}>Login</p></li>
                                                    <li><p><a href="" onClick={() => this.viewMoreSafety()}>View More</a></p></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3">
                                        <div className="widget-area no-padding blank">
                                            <div className="social-widget">
                                                <div className="title-text">Safety Staging</div>
                                                <span id="twitter1"><i className="fa fa-life-ring"></i></span>
                                                <ul>
                                                    <li className="login-button" data-toggle="modal" data-target="#exampleModal"><p onClick={() => this.loginsafetystaging()}>Login</p></li>
                                                    <li><p><a href="" onClick={() => this.viewMoreSafety()}>View More</a></p></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3">
                                        <div className="widget-area no-padding blank">
                                            <div className="social-widget">
                                                <div className="title-text">Safety Contract</div>
                                                <span id="twitter1"><i className="fa fa-life-ring"></i></span>
                                                <ul>
                                                    <li className="login-button" data-toggle="modal" data-target="#exampleModal"><p onClick={() => this.loginsafetycontract()}>Login</p></li>
                                                    <li><p><a href="" onClick={() => this.viewMoreSafety()}>View More</a></p></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3">
                                        <div className="widget-area no-padding blank">
                                            <div className="social-widget">
                                                <div className="title-text">Alcovisor</div>
                                                <span id="googleplus"><i class="fa fa-ship"></i></span>
                                                <ul>
                                                    <li className="login-button" data-toggle="modal" data-target="#exampleModal"><p onClick={() => this.loginalcovisor()}>Login</p></li>
                                                    <li><p><a href="" onClick={() => this.viewMoreAlcovisor()}>View More</a></p></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3">
                                        <div className="widget-area no-padding blank">
                                            <div className="social-widget">
                                                <div className="title-text">Navcom </div>
                                                <span id="twitter" class=""> <img class="social-widget4" src={twent24} width="25" /></span>
                                                <ul>
                                                    <li className="login-button" data-toggle="modal" data-target="#exampleModal"><p onClick={() => this.loginNavcom()}>Login</p></li>
                                                    <li><p><a href="" onClick={() => this.viewMoreNavCom()}>View More</a></p></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-3"></div> */}

                                    <div className="col-md-6 col-lg-3">
                                        <div className="widget-area no-padding blank">
                                            <div className="social-widget">
                                                <div className="title-text">Ship Recycling System</div>
                                                <span id="facebook" className=""> <img class="social-widget5" src={srsimage} width="25" /></span>
                                                <ul>
                                                    <li className="login-button" data-toggle="modal" data-target="#exampleModal"><p onClick={() => this.loginsrs()}>Login</p></li>
                                                    <li><p><a href="" onClick={() => this.viewMoreSRS()}>View More</a></p></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3">
                                        <div className="widget-area no-padding blank">
                                            <div className="social-widget">
                                                <div className="title-text">Vessel Inspection System</div>
                                                <span id="inspection" className=""> <img class="social-widget6" src={visimage} width="25" /></span>
                                                <ul>
                                                    <li className="login-button" data-toggle="modal" data-target="#exampleModal"><p onClick={() => this.loginvis()}>Login</p></li>
                                                    <li><p><a href="" onClick={() => this.viewMoreVIS()}>View More</a></p></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-1 text-center"></div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default HomePage;
