import { UserManager } from "oidc-client";

const userManager = new UserManager({
  authority: process.env.REACT_APP_IDENTITY_CONFIG_AUTHORITY,
  client_id: process.env.REACT_APP_IDENTITY_CONFIG_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_IDENTITY_CONFIG_REDIRECT_URI,
  response_type: process.env.REACT_APP_IDENTITY_CONFIG_RESPONSE_TYPE,
  scope: 'openid profile email skoruba_identity_admin_api',
  silent_redirect_uri: process.env.API_BASE_URL,
  post_logout_redirect_uri: process.env.REACT_APP_IDENTITY_CONFIG_POST_LOGOUT_REDIRECT_URI,
  clockSkew: 60*60*24*365*100
});

export default userManager;
